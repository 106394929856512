import React from 'react'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { TinySlider } from '_molecules'
import ThawLogo from '_images/cases/thaw.svg'
import MeliLogo from '_images/cases/meli.svg'
import TapcartLogo from '_images/cases/tapcart.svg'
import AESLogo from '_images/cases/aes.svg'
import BemisLogo from '_images/cases/bemis.svg'
import CRULogo from '_images/cases/cru.svg'
import { Container, SliderNav } from '_components/atoms'

import Slide from './slide'
import ThawCase from './projects/thaw.webp'
import MeliCase from './projects/meli.webp'
import TapcartCase from './projects/tapcart.webp'
import AESCase from './projects/aes.webp'
import BemisCase from './projects/bemis.webp'
import CRUCase from './projects/cru.webp'
import SlideStyle from './slide.module.css'
import styles from './style.module.css'

export const SLIDER_OPTIONS = {
  navContainer: '#cases-nav',
  gutter: 16,
  lazyload: true,
  mouseDrag: true,
  loop: true,
  autoHeight: true,
  autoWidth: true,
  autoplay: false,
  controls: false, // remove built-in nav buttons
}

const Carousel = () => {
  const slides = [
    {
      category: <FormattedMessage id="home.ourWork.iot" />,
      caseBrand: ThawLogo,
      brandName: 'Thaw',
      description: <FormattedMessage id="home.ourWork.thaw" />,
      cta: <FormattedMessage id="home.ourWork.viewCase" />,
      bgColor: '#F84E2F',
      darkContent: false,
      image: ThawCase,
      imageStyle: { height: 600, marginTop: -50 },
      caseLink: '/portfolio/thaw/',
    },
    {
      category: <FormattedMessage id="home.ourWork.voiceAssistant" />,
      caseBrand: MeliLogo,
      brandName: 'MELI',
      description: <FormattedMessage id="home.ourWork.meli" />,
      cta: <FormattedMessage id="home.ourWork.viewCase" />,
      bgColor: '#F7E000',
      darkContent: true,
      image: MeliCase,
      imageStyle: { marginTop: -50 },
      caseLink: '/portfolio/meli/',
    },
    {
      category: <FormattedMessage id="home.ourWork.ecommerce" />,
      caseBrand: TapcartLogo,
      brandName: 'Tapcart',
      description: <FormattedMessage id="home.ourWork.tapcart" />,
      cta: <FormattedMessage id="home.ourWork.viewCase" />,
      bgColor: '#363EA9',
      darkContent: false,
      image: TapcartCase,
      imageStyle: { height: 600, marginTop: -50 },
      caseLink: '/portfolio/tapcart/',
    },
    {
      category: <FormattedMessage id="home.ourWork.web" />,
      caseBrand: AESLogo,
      brandName: 'AES',
      description: <FormattedMessage id="home.ourWork.aes" />,
      cta: <FormattedMessage id="home.ourWork.viewCase" />,
      bgColor: '#0477B1',
      darkContent: false,
      image: AESCase,
      imageStyle: { height: 550, marginTop: 0 },
      caseLink: '/portfolio/aes-vpp/',
    },
    {
      category: <FormattedMessage id="home.ourWork.iot" />,
      caseBrand: BemisLogo,
      brandName: 'Bemis',
      description: <FormattedMessage id="home.ourWork.bemis" />,
      cta: <FormattedMessage id="home.ourWork.getInTouch" />,
      bgColor: '#0058FF',
      darkContent: false,
      image: BemisCase,
      imageStyle: { height: 600, marginTop: -50 },
      caseLink: '/contact/',
    },
    {
      category: <FormattedMessage id="home.ourWork.socialMedia" />,
      caseBrand: CRULogo,
      brandName: 'CRU',
      description: <FormattedMessage id="home.ourWork.cru" />,
      cta: <FormattedMessage id="home.ourWork.viewCase" />,
      bgColor: '#1E1F23',
      darkContent: false,
      image: CRUCase,
      imageStyle: { height: 600, marginTop: -50 },
      caseLink: '/portfolio/cru-app/',
    },
  ]

  return (
    <>
      <TinySlider id="cases-slider" options={SLIDER_OPTIONS} className={styles.carouselContainer}>
        {slides.map(slide => (
          <div>
            <Slide
              key={slide.brandName}
              category={slide.category}
              caseBrand={slide.caseBrand}
              brandName={slide.brandName}
              description={slide.description}
              bgColor={slide.bgColor}
              darkContent={slide.darkContent}
              image={slide.image}
              imageStyle={slide.imageStyle}
              caseLink={slide.caseLink}
              cta={slide.cta}
            />
          </div>
        ))}
      </TinySlider>
      <Container>
        <div id="cases-nav" className={SlideStyle.testimonialsIcons}>
          {slides.map(({ brandName }) => (
            <SliderNav className={SlideStyle.dots} key={brandName} isWhite />
          ))}
        </div>
      </Container>
    </>
  )
}

export default Carousel
