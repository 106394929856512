import React from 'react'
import { Row } from 'react-simple-flex-grid'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { Container } from '_atoms'
import Carousel from '_components/molecules/carousel'

import styles from './styles.module.css'

function UseCases() {
  return (
    <div>
      <Container>
        <Row gutter={20}>
          <h2 className={styles.title}>
            <FormattedMessage id="home.ourWork.text1" />
            <span>.</span>
          </h2>
        </Row>
      </Container>
      <Carousel />
    </div>
  )
}

export default UseCases
