import React from 'react'
import Fade from 'react-reveal/Fade'
import classNames from 'classnames/bind'
import { Row, Col } from 'react-simple-flex-grid'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { Button, Container } from '_components/atoms'
import AlarmIcon from '_images/nearshore/alarm.svg'
import MessageIcon from '_images/nearshore/message.svg'
import MoneyIcon from '_images/nearshore/money.svg'
import NearshoreCover from '_images/nearshore/nearshore.webp'

import styles from './styles.module.css'

const cx = classNames.bind(styles)

const WhyNearshoring = () => {
  const intlBreakLineValues = {
    span: (...chunks) => (
      <span>
        <b>{chunks}</b>
      </span>
    ),
  }

  return (
    <section className={cx('section', 'safariScrollFlickerFix')}>
      <Container>
        <Fade bottom>
          <h2 className={styles.nearshoreTitle}>
            <FormattedMessage id="home.whyNearshoring.title" />
            <span>?</span>
          </h2>
        </Fade>
        <Fade delay={200} bottom>
          <Row className={styles.nearshoreContent} gutter={20}>
            <Col lg={10} sm={12}>
              <p className={styles.nearshoreDescription}>
                <FormattedMessage
                  values={intlBreakLineValues}
                  id="home.whyNearshoring.description"
                />
              </p>
            </Col>
            <Col lg={2} sm={12}>
              <Button href="/contact" small type="primaryOutline">
                <FormattedMessage id="home.whyNearshoring.cta" />
              </Button>
            </Col>
          </Row>
        </Fade>
        <Fade delay={200} bottom cascade>
          <Row gutter={100}>
            <Col className={styles.card} lg={4} sm={12}>
              <img
                className={styles.nearshoreFeatureImage}
                src={AlarmIcon}
                alt="icon"
                height={32}
                width={32}
              />
              <p className={styles.nearshoreFeatureTitle}>
                <FormattedMessage
                  values={intlBreakLineValues}
                  id="home.whyNearshoring.feature1Title"
                />
              </p>
              <p className={styles.nearshoreFeatureDescription}>
                <FormattedMessage id="home.whyNearshoring.feature1Description" />
              </p>
            </Col>
            <Col className={styles.card} lg={4} sm={12}>
              <img
                className={styles.nearshoreFeatureImage}
                src={MoneyIcon}
                alt="icon"
                height={32}
                width={32}
              />
              <p className={styles.nearshoreFeatureTitle}>
                <FormattedMessage
                  values={intlBreakLineValues}
                  id="home.whyNearshoring.feature2Title"
                />
              </p>
              <p className={styles.nearshoreFeatureDescription}>
                <FormattedMessage id="home.whyNearshoring.feature2Description" />
              </p>
            </Col>
            <Col className={styles.card} lg={4} sm={12}>
              <img
                className={styles.nearshoreFeatureImage}
                src={MessageIcon}
                alt="icon"
                height={32}
                width={32}
              />
              <p className={styles.nearshoreFeatureTitle}>
                <FormattedMessage
                  values={intlBreakLineValues}
                  id="home.whyNearshoring.feature3Title"
                />
              </p>
              <p className={styles.nearshoreFeatureDescription}>
                <FormattedMessage id="home.whyNearshoring.feature3Description" />
              </p>
            </Col>
          </Row>
        </Fade>
        <Fade bottom>
          <img src={NearshoreCover} className={styles.nearshoreCover} alt="Cheesecake cover" />
        </Fade>
      </Container>
    </section>
  )
}

export default WhyNearshoring
