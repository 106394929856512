import React from 'react'
import classNames from 'classnames/bind'
import { FormattedMessage } from 'gatsby-plugin-intl'

import { Container, Text } from '_atoms'
import { FEATURED_BRANDS, FOUNDED_BRANDS } from '_config/home-variables'
import { BrandsFixed, BrandsFluid } from '_components/molecules'

import ShowProjectByBrand from '../show-projects-by-brand'

import styles from './styles.module.css'

const cx = classNames.bind(styles)

function Brands() {
  return (
    <section className={cx('sub-section', 'blueSection', 'safariScrollFlickerFix')}>
      <Container className={cx('safariScrollFlickerFix', 'blueSectionContainer')}>
        <Text className={styles.brandsSubtitle} align="center" size="24" color="white">
          <strong>
            <FormattedMessage id="home.ourWork.text1" />
          </strong>{' '}
          <FormattedMessage id="home.ourWork.text2" />
        </Text>
        <div className={styles.brandsRow}>
          {FEATURED_BRANDS.map(brand => (
            <ShowProjectByBrand
              key={`featured-brand-${brand.name}`}
              brandClassName={styles.pressBrands}
              projectClassName={styles.projectBrands}
              projects={brand.projects}
              brand={brand.name}
            />
          ))}
        </div>
      </Container>

      <Container className={styles.safariScrollFlickerFix}>
        <Text className={styles.brandsSubtitle} align="center" size="24" color="white">
          <FormattedMessage id="home.ourProjects.text1" />
          <strong>
            {' '}
            <FormattedMessage id="home.ourProjects.text2" />{' '}
          </strong>
          <FormattedMessage id="home.ourProjects.text3" />
        </Text>
        <div className={cx('foundedByRow', 'foundedByRowDesktopVersion')}>
          {FOUNDED_BRANDS.map(brand => (
            <a
              className={styles.foundedByBrands}
              key={`founded-by-brand-${brand.name}`}
              href={brand.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <BrandsFixed name={brand.name} />
            </a>
          ))}
        </div>
        <div className={cx('foundedByRow', 'foundedByRowMobileVersion')}>
          {FOUNDED_BRANDS.map(brand => (
            <a
              className={styles.foundedByBrands}
              key={`founded-by-brand-${brand.name}`}
              href={brand.url}
              rel="noopener noreferrer"
              target="_blank"
            >
              <BrandsFluid name={brand.name} />
            </a>
          ))}
        </div>
      </Container>
    </section>
  )
}

export default Brands
