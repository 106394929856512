import React from 'react'
import Fade from 'react-reveal/Fade'
import classNames from 'classnames/bind'
import { FormattedMessage } from 'gatsby-plugin-intl'
import { Row, Col } from 'react-simple-flex-grid'

import { Container } from '_atoms'

import styles from './styles.module.css'

const cx = classNames.bind(styles)

const HOW_ITEMS = [
  <FormattedMessage id="home.how.feature1" />,
  <FormattedMessage id="home.how.feature2" />,
  <FormattedMessage id="home.how.feature3" />,
  <FormattedMessage id="home.how.feature4" />,
  <FormattedMessage id="home.how.feature5" />,
]

function How() {
  const intlBreakLineValues = {
    span: (...chunks) => <span>{chunks}</span>,
  }

  return (
    <section className={cx('section', 'safariScrollFlickerFix')}>
      <Container>
        <Row gutter={20}>
          <Col xs={12} sm={12} md={1}>
            <div className={styles.titleContainer}>
              <h2 className={styles.howTitle}>
                <FormattedMessage values={intlBreakLineValues} id="home.how.title" />
              </h2>
            </div>
          </Col>
          <Col xs={12} sm={12} md={11}>
            <Fade distance="20%" delay={200} bottom>
              <div className="container">
                {HOW_ITEMS.map((item, index) => (
                  <a href="/services" key={item} id={index} className={styles.specialLink}>
                    <span>0{index + 1} </span>
                    {item}
                  </a>
                ))}
              </div>
            </Fade>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default How
