import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby-plugin-intl'

import { Button } from '_components/atoms'

import styles from './style.module.css'

const Slide = ({
  category,
  caseBrand,
  brandName,
  description,
  image,
  bgColor,
  darkContent,
  caseLink,
  cta,
}) => (
  <div className={styles.slide} style={{ backgroundColor: bgColor }}>
    <div className={styles.container}>
      <div className={styles.content}>
        <p className={darkContent ? styles.categoryDark : styles.categoryLight}>
          &#8250; {category}
        </p>
        <img src={caseBrand} alt={brandName} className={styles.caseBrand} />
        <p className={darkContent ? styles.descriptionDark : styles.descriptionLight}>
          {description}
        </p>
        <div className={styles.CTAcase}>
          <Link to={caseLink}>
            <Button
              type={darkContent ? 'primaryOutline' : 'outlineWhite'}
              small
              href={caseLink}
              className={styles.CTAbutton}
            >
              {cta}
            </Button>
          </Link>
        </div>
      </div>
      <div id={brandName} className={styles.image}>
        <img className={styles.caseImage} src={image} alt="Slide" />
      </div>
    </div>
  </div>
)

Slide.propTypes = {
  category: PropTypes.string.isRequired,
  caseBrand: PropTypes.string.isRequired,
  brandName: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  bgColor: PropTypes.string.isRequired,
  caseLink: PropTypes.string.isRequired,
  darkContent: PropTypes.bool.isRequired,
  cta: PropTypes.string.isRequired,
}

export default Slide
